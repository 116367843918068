import { computed, ref, watch } from "vue"
import { defineStore } from "pinia"
import type { AssetsSummary } from "@common/models/asset"
import type { AccountBalanceSummary } from "@/services/api/registry.model"
import type { Transaction } from "@/services/api/transaction.service"
import { useAssetService, useRegistryService, useTransactionService } from "@/services/service-container"
import { useMainStore } from "@/store"

export const useEnterpriseDashboardMetricsStore = defineStore("enterpriseDashboardMetrics", () => {
  const isLoading = ref<boolean>(true)

  const accountBalanceSummary = ref<AccountBalanceSummary>()
  const numAssets = ref<number>()
  const transactions = ref<Transaction[]>()

  const assetService = useAssetService()
  const registryService = useRegistryService()
  const transactionService = useTransactionService()

  const mainStore = useMainStore()

  const accountId = computed(() => mainStore.account.id)

  const loadMetrics = async () => {
    isLoading.value = true
    const results = await Promise.allSettled([
      registryService.getAllTimeBalanceSummary(),
      assetService.getAssetsSummary(),
      transactionService.listTransactions({ per_page: 1 }),
    ])

    const [balanceSummaryResponse, assetsSummaryResponse, transactionsResponse] = results.map((result) => {
      if (result.status === "fulfilled") {
        return result.value
      } else {
        console.error("Error loading dashboard metrics", result.reason)
        return undefined
      }
    })

    accountBalanceSummary.value = balanceSummaryResponse && (balanceSummaryResponse as AccountBalanceSummary)
    numAssets.value = assetsSummaryResponse && (assetsSummaryResponse as AssetsSummary)?.assetCount
    transactions.value = transactionsResponse && (transactionsResponse as { data: Transaction[] })?.data
  }

  const $reset = () => {
    isLoading.value = true
    accountBalanceSummary.value = undefined
    numAssets.value = undefined
    transactions.value = undefined
  }

  watch(
    accountId,
    () => {
      $reset()
      loadMetrics()
    },
    { immediate: true }
  )

  return {
    // state
    isLoading,
    accountBalanceSummary,
    numAssets,
    transactions,
    // actions
    loadMetrics,
    $reset,
  }
})
