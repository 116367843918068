import { computed } from "vue"

import type { ButtonColor, ButtonSize, ButtonVariant } from "./WcButton"

type ButtonProps = {
  color: ButtonColor
  isDisabled: boolean
  size: ButtonSize
  variant: ButtonVariant
}

type ButtonState = "default" | "hover" | "active"

type ColorScheme = {
  text: string
  bg: string
  border: string
}

type ColorSchemes = {
  [variant in ButtonVariant]: {
    [color in ButtonColor | "disabled"]: {
      [state in ButtonState]: ColorScheme
    }
  }
}

// This can probably be streamlined eventually, but while this is still new, keeping it explicit makes it easy to adjust as needed.
const colorSchemes: ColorSchemes = {
  contained: {
    primary: {
      default: { text: "text-white", bg: "bg-blue-500", border: "border-blue-500" },
      hover: { text: "text-white", bg: "bg-blue-500", border: "border-blue-500" },
      active: { text: "text-white", bg: "bg-blue-800", border: "border-blue-800" },
    },
    secondary: {
      default: { text: "text-white", bg: "bg-neutral-500", border: "border-neutral-500" },
      hover: { text: "text-white", bg: "bg-neutral-500", border: "border-neutral-500" },
      active: { text: "text-white", bg: "bg-neutral-700", border: "border-neutral-700" },
    },
    "accent-blue": {
      default: { text: "text-black", bg: "bg-highlight", border: "border-highlight" },
      hover: { text: "text-black", bg: "bg-highlight-hover", border: "border-highlight-hover" },
      active: { text: "text-black", bg: "bg-highlight-active", border: "border-highlight-active" },
    },
    "accent-yellow": {
      default: { text: "text-black", bg: "bg-accent", border: "border-accent" },
      hover: { text: "text-black", bg: "bg-accent-hover", border: "border-accent-hover" },
      active: { text: "text-black", bg: "bg-accent-active", border: "border-accent-active" },
    },
    "primary-dark": {
      default: { text: "text-black", bg: "bg-neutral-100", border: "border-neutral-100" },
      hover: { text: "text-black", bg: "bg-neutral-100", border: "border-neutral-100" },
      active: { text: "text-black", bg: "bg-neutral-200", border: "border-neutral-200" },
    },
    disabled: {
      default: { text: "text-white", bg: "bg-neutral-300", border: "border-neutral-300" },
      hover: { text: "text-white", bg: "bg-neutral-300", border: "border-neutral-300" },
      active: { text: "text-white", bg: "bg-neutral-300", border: "border-neutral-300" },
    },
  },
  outlined: {
    primary: {
      default: { text: "text-black", bg: "bg-transparent", border: "border-blue-500" },
      hover: { text: "text-black", bg: "bg-neutral-100", border: "border-blue-500" },
      active: { text: "text-black", bg: "bg-neutral-300", border: "border-blue-500" },
    },
    secondary: {
      default: { text: "text-black", bg: "bg-transparent", border: "border-neutral-500" },
      hover: { text: "text-black", bg: "bg-neutral-100", border: "border-neutral-500" },
      active: { text: "text-neutral-500", bg: "bg-neutral-300", border: "border-neutral-500" },
    },
    "accent-blue": {
      default: { text: "text-highlight", bg: "bg-transparent", border: "border-highlight" },
      hover: { text: "text-highlight", bg: "bg-transparent", border: "border-highlight" },
      active: { text: "text-highlight", bg: "bg-transparent", border: "border-highlight" },
    },
    "accent-yellow": {
      default: { text: "text-accent", bg: "bg-transparent", border: "border-accent" },
      hover: { text: "text-accent", bg: "bg-transparent", border: "border-accent" },
      active: { text: "text-accent", bg: "bg-transparent", border: "border-accent" },
    },
    "primary-dark": {
      default: { text: "text-white", bg: "bg-transparent", border: "border-neutral-100" },
      hover: { text: "text-white", bg: "bg-transparent", border: "border-neutral-100" },
      active: { text: "text-white", bg: "bg-transparent", border: "border-neutral-100" },
    },
    disabled: {
      default: { text: "text-neutral-300", bg: "bg-transparent", border: "border-neutral-300" },
      hover: { text: "text-neutral-300", bg: "bg-transparent", border: "border-neutral-300" },
      active: { text: "text-neutral-300", bg: "bg-transparent", border: "border-neutral-300" },
    },
  },
  text: {
    primary: {
      default: { text: "text-blue-500", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-blue-500", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-blue-500", bg: "bg-black/20", border: "border-transparent" },
    },
    secondary: {
      default: { text: "text-neutral-500", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-neutral-500", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-neutral-500", bg: "bg-black/20", border: "border-transparent" },
    },
    "accent-blue": {
      default: { text: "text-highlight", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-highlight", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-highlight", bg: "bg-black/20", border: "border-transparent" },
    },
    "accent-yellow": {
      default: { text: "text-accent", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-accent", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-accent", bg: "bg-black/20", border: "border-transparent" },
    },
    "primary-dark": {
      default: { text: "text-white", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-white", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-white", bg: "bg-black/20", border: "border-transparent" },
    },
    disabled: {
      default: { text: "text-neutral-300", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-neutral-300", bg: "bg-transparent", border: "border-transparent" },
      active: { text: "text-neutral-300", bg: "bg-transparent", border: "border-transparent" },
    },
  },
}

const classesBySize = {
  "extra-small": "px-[11px] py-[5px] text-button-extra-small",
  small: "px-[13px] py-[7px] text-button-small",
  medium: "px-[15px] py-[9px] text-button-medium",
  large: "px-[19px] py-[11px] text-button-large",
}

const shadowClassesByVariant = {
  contained: "hover-shadow active-shadow",
  outlined: "",
  text: "",
}

const underlineClassesByVariant = {
  contained: "",
  outlined: "",
  text: "underline active:no-underline hover:no-underline",
}

const getColorClassesByState = (props: ButtonProps, state: ButtonState, prefix?: string) => {
  const scheme = colorSchemes[props.variant!]?.[props.isDisabled ? "disabled" : props.color!]?.[state]
  if (!scheme) {
    return ""
  }
  return Object.values(scheme)
    .map((scheme) => (prefix ? `${prefix}:${scheme}` : scheme))
    .join(" ")
}

const useButtonClasses = (props: ButtonProps) => {
  const getColorClasses = () => {
    const baseClasses = getColorClassesByState(props, "default")
    const hoverClasses = !props.isDisabled ? getColorClassesByState(props, "hover", "hover") : ""
    const activeClasses = !props.isDisabled ? getColorClassesByState(props, "active", "active") : ""
    return [baseClasses, hoverClasses, activeClasses].filter(Boolean).join(" ")
  }

  const buttonClasses = computed(() => {
    const baseClasses = "transition-colors border border-1 rounded"
    const colorClasses = getColorClasses()
    const sizeClasses = classesBySize[props.size!]
    const shadowClasses = !props.isDisabled && shadowClassesByVariant[props.variant]
    const underlineClasses = !props.isDisabled && underlineClassesByVariant[props.variant]

    return [baseClasses, colorClasses, shadowClasses, sizeClasses, underlineClasses].filter(Boolean).join(" ")
  })

  return buttonClasses
}

export default useButtonClasses
