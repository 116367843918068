<template>
  <AppPage>
    <AppPageHeader :backlink-route="backlinkRoute" :backlink-href="backlinkHref" :backlink-text="backlinkText" />
    <AppPageContent>
      <StoryLoader v-if="!story" :story-id="storyId" @load="onStoryLoad" @not-found="onStoryNotFound" />

      <StoryDetails v-else-if="story && storyMetaData" :story="story" :meta-data="storyMetaData" :start-collapsed="procurementOptions">
        <template #cta>
          <ShowProcurementModule v-if="storyMetaData.isAvailableToOrder" type="button" button-classes="bg-highlight">
            See Procurement Options
          </ShowProcurementModule>
          <template v-else>
            <ShowCustomOrderForm v-if="!isCustomOrderFormSubmitted" button-classes="bg-highlight"> Custom Order </ShowCustomOrderForm>
            <div v-else class="flex h-[46px] items-center gap-4">
              <img src="@/static/img/icons/mini/checkcircle.svg" width="32px" height="32px" />
              <p class="text-body-3 !font-semibold text-blue">You're all set!</p>
            </div>
          </template>
        </template>
      </StoryDetails>

      <IncludeProcurementModule v-if="story" id="procurement" :story="story" />
    </AppPageContent>

    <IncludeCustomOrderForm v-if="story" :story="story" />
  </AppPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"

import ShowProcurementModule from "~/components/procurement/ShowProcurementModule.vue"
import ShowCustomOrderForm from "~/components/custom-order-form/ShowCustomOrderForm.vue"
import IncludeProcurementModule from "~/components/procurement/IncludeProcurementModule.vue"
import IncludeCustomOrderForm from "~/components/custom-order-form/IncludeCustomOrderForm.vue"

import { setPageDescription, setPageImage, setPageTitle } from "@/metatags"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import { getEnvironment } from "@/environment"
import { useMarketStore } from "./market.state"
import { Story, MarketplaceStory } from "@common/models/story"

import type { StoryMetaData, LoadableStory } from "./story.utils"
import StoryDetails from "./StoryDetails.vue"
import StoryLoader from "./StoryLoader.vue"

const { WWW_BASE_URL } = getEnvironment()
const router = useRouter()
const marketStore = useMarketStore()

const props = defineProps<{ storyId: number; procurementOptions?: boolean }>()

const backlinkRoute = computed(() => {
  const backUrl = router.options.history.state.back
  return backUrl ? router.resolve({ path: `${backUrl}` }) : undefined
})

const backlinkHref = computed(() => {
  return !backlinkRoute.value ? `${WWW_BASE_URL}/openeac-exchange` : undefined
})

const backlinkText = computed(() => {
  if (backlinkRoute.value?.name === "wc-openeac-exchange-search") {
    return "Back to Search"
  } else {
    return "Back to OpenEAC Exchange"
  }
})

const story = ref<Story | MarketplaceStory | null>(null)
const storyMetaData = ref<StoryMetaData | null>(null)
const isSearchHeaderVisible = ref<boolean>(false)

const onStoryLoad = (loadedStory: LoadableStory, loadedMetaData: StoryMetaData) => {
  story.value = loadedStory
  storyMetaData.value = loadedMetaData

  if (loadedStory) {
    setPageTitle(loadedStory.name)
    setPageDescription(loadedStory.shortDescription ?? undefined)
    setPageImage(loadedStory.imageUrl ?? undefined)
  }
}

const onStoryNotFound = () => {
  router.push({ name: "wc-404" })
}

const isCustomOrderFormSubmitted = computed(() => {
  return marketStore.customOrderSubmittedStoryIds.includes(props.storyId)
})

onMounted(async () => {
  if (marketStore.hasActiveFilters) {
    isSearchHeaderVisible.value = true
  }
})
</script>
