<template>
  <ButtonCTA v-if="!store.isVisible" v-bind="$props" @click="store.show">
    <slot />
  </ButtonCTA>
</template>

<script setup lang="ts">
import ButtonCTA from "~/components/v3/ui/ButtonCTA.vue"
import type { Props } from "~/components/v3/ui/ButtonCTA"

import { useProcurementStore } from "./procurement-store"

defineProps<Props>()

const store = useProcurementStore()
</script>
