<template>
  <AppPageSection>
    <AppPageSectionHeader>Transactions</AppPageSectionHeader>
    <div id="transactions" class="overflow-x-scroll">
      <AccountTransactionsTable :kind="INCLUDED_KINDS" :summary="false" />
    </div>
  </AppPageSection>
</template>

<script setup lang="ts">
import { AppPageSection, AppPageSectionHeader } from "@/components/layout"
import AccountTransactionsTable from "@/modules/accounts/components/AccountTransactionsTable.vue"

const INCLUDED_KINDS = ["listing_sale", "purchase"]
</script>
