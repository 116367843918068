<template>
  <ButtonCTA v-if="!store.isVisible" v-bind="$props" @click="store.show">
    <slot />
  </ButtonCTA>
</template>

<script setup lang="ts">
import posthog from "posthog-js"

import ButtonCTA from "~/components/v3/ui/ButtonCTA.vue"
import type { Props } from "~/components/v3/ui/ButtonCTA"

import { useCustomOrderStore } from "./custom-order-store"

defineProps<Props>()

const store = useCustomOrderStore()

const openCustomOrderModal = () => {
  posthog.capture(`Clicked on "Custom Order" from the Story View`)
  store.show()
}
</script>
