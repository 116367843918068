<template>
  <div class="text-body-1 w-full">
    <AppPageHeader :backlink-route="{ name: 'wc-enterprise-eac-activity-log' }" backlink-text="Back to Activity Log" />
    <div class="wc-page-container">
      <div class="mt-12 flex justify-center">
        <div class="basis-full md:basis-1/2">
          <h2 class="text-heading-4">Thank you</h2>
        </div>
      </div>
      <div class="mt-10 flex justify-center pb-14">
        <div class="basis-full rounded-sm bg-neutral-200 p-8 md:basis-1/2">
          <h3 class="text-subheading-large-bold">Purchase summary</h3>
          <div class="items-end border-b border-neutral-500 px-2 py-10">
            <div class="flex justify-between">
              <div class="text-body-1">Account</div>

              <div v-if="payment" class="text-body-1-strong">{{ payment.account.name }}</div>
              <Skeleton v-else height="1em" width="50%" />
            </div>
            <div class="mt-6 flex justify-between">
              <div class="text-body-1">Purchase date</div>
              <div v-if="payment" class="text-body-1-strong">{{ format(new Date(payment.createdTime), "LLLL d, Y") }}</div>
              <Skeleton v-else height="1em" width="50%" />
            </div>
            <div class="mt-6 flex justify-between">
              <div class="text-body-1">Payment ID</div>
              <div v-if="payment" class="text-body-1-strong">{{ payment.id }}</div>
              <Skeleton v-else height="1em" width="50%" />
            </div>
          </div>
          <div class="border-b border-neutral-500 px-2 py-6">
            <div class="flex justify-between">
              <div>From</div>
              <div class="text-body-1-strong">
                <div v-for="story in stories" :key="story.id">
                  <router-link :to="{ name: 'wc-story', params: { storyId: story.id } }" class="underline">{{ story.name }}</router-link>
                </div>
              </div>
            </div>
            <div class="mt-6 flex justify-between">
              <div>Amount</div>
              <div v-if="stories.length" class="text-body-1-strong">{{ totalEacs }} ({{ totalCarbon }})</div>
              <Skeleton v-else height="1em" width="50%" />
            </div>
          </div>
          <div class="flex justify-between px-2 pt-8">
            <div class="text-body-1-strong">Total paid</div>
            <div v-if="stories.length" class="text-body-1-strong">{{ totalPaid }}</div>
            <Skeleton v-else height="1em" width="50%" />
          </div>
          <div class="mt-10 flex justify-center">
            <div>
              <router-link :to="{ name: 'wc-eacs' }">
                <ButtonCTA width="full" button-classes="bg-blue-500 hover:bg-blue-300 text-white ph-no-capture">View EACs</ButtonCTA>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchPostEffect } from "vue"
import AppPageHeader from "@/components/layout/AppPageHeader.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { useListingService } from "@/services/service-container"
import { format } from "date-fns"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import formatCurrencyInPenniesFactory from "@common/utils/formatCurrencyInPenniesFactory"
import { useTransactionService } from "@/services/service-container"
import { Payment } from "@/services/api/transaction.service"
import { useRouter } from "vue-router"
import { MarketplaceStory } from "@common/models/story"
import Skeleton from "primevue/skeleton"

const props = defineProps<{
  paymentId: number
}>()
const formatCurrencyInPennies = formatCurrencyInPenniesFactory()
const listingService = useListingService()
const transactionService = useTransactionService()
const router = useRouter()

const payment = ref<Payment>()
const stories = ref<MarketplaceStory[]>([])

watchPostEffect(async () => {
  try {
    ;[payment.value, { stories: stories.value }] = await Promise.all([
      transactionService.getPayment(props.paymentId),
      listingService.getAllListings({ paymentId: props.paymentId, available: false }),
    ])
  } catch (err: any) {
    if (err.toString().includes("not found")) {
      await router.push({ name: "wc-404" })
      return
    }
    throw err
  }
})

const totalEacs = computed(() => {
  const amount = stories.value.reduce((acc, listing) => acc + listing.totalEacs, 0)
  return getFormattedEacQuantity(amount, EacMeasurementParameter.Electricity)
})
const totalCarbon = computed(() => {
  const amount = stories.value.reduce((acc, listing) => acc + listing.totalGco2, 0)
  return getFormattedEacQuantity(amount, EacMeasurementParameter.CarbonDioxide)
})
const totalPaid = computed(() => {
  return formatCurrencyInPennies(payment.value?.penniesUsd || 0)
})
</script>
