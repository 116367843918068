<template>
  <div id="map" ref="mapContainer" class="relative size-full" :class="classes" v-bind="$attrs">
    <div v-if="isErrorState">Map not supported :(</div>
  </div>
  <small v-if="!isErrorState" class="italic">Exact locations are hidden for privacy</small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, render, watch } from "vue"
import { colors } from "@common/global"
import type { Point } from "geojson"
import type { GeoJSONSource, LngLatLike } from "mapbox-gl"
import mapboxgl from "mapbox-gl"
import type { Location } from "./WcMap.utils"
import {
  boundsToCenterCoordinate,
  ClusterMode,
  customizeClusterMarkers,
  locationToFeature,
  locationsToBounds,
  locationsToColorClusters,
  makeClusterProperties,
  MAPBOX_ACCESS_TOKEN,
} from "./WcMap.utils"

// This is WattCarbon's customized map design, created and managed in the Mapbox Design Studio
// https://studio.mapbox.com/styles/wattcarbonservices/cltg7tfeh000v01ph4fzhdss1/
const MAPBOX_DESIGN_STUDIO_STYLESHEET = "mapbox://styles/wattcarbonservices/cltg7tfeh000v01ph4fzhdss1"

const props = defineProps<{
  clusterMode?: ClusterMode
  locations: Array<Location>
  locationsArePrecise?: boolean
}>()

const slots = defineSlots<{
  popup?: (metadata: any) => any
}>()

const hasPopupSlot = slots.popup !== undefined

const locations = computed(() => props.locations ?? [])

const mapContainer = ref<HTMLElement | null>(null)

const isErrorState = ref<boolean>(false)

const classes = computed(() => {
  if (!isErrorState.value) return ""
  return "bg-neutral-200 text-neutral-500 text-2xl flex flex-col justify-center text-center"
})

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

const renderMap = () => {
  if (!mapboxgl.accessToken) {
    console.warn("No WattCarbon Mapbox access token was supplied.")
    isErrorState.value = true
    return
  }

  if (!mapboxgl.supported()) {
    console.warn("WebGL not enabled in this browser.")
    isErrorState.value = true
    return
  }

  if (mapContainer.value) {
    const bounds = locationsToBounds(locations.value)

    const colorClusters = locationsToColorClusters(locations.value)
    const hasColorClusters = colorClusters.length > 0

    const map = new mapboxgl.Map({
      bounds: locationsToBounds(locations.value),
      container: mapContainer.value, // container ID
      style: MAPBOX_DESIGN_STUDIO_STYLESHEET,
      center: boundsToCenterCoordinate(bounds),
      zoom: 6,
      // Only allow full zoom when locations are precise
      maxZoom: props.locationsArePrecise ? undefined : 11,
    })

    map.on("load", () => {
      // Add +/- buttons to zoom in and out
      map.addControl(new mapboxgl.NavigationControl({ showCompass: false }))

      // Add the locations array as a geojson data source
      map.addSource("points", {
        type: "geojson",
        cluster: true,
        clusterProperties: makeClusterProperties(colorClusters),
        data: {
          type: "FeatureCollection",
          features: locations.value.map(locationToFeature),
        },
      })

      // Add a layer showing clusters
      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "points",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": colors.highlight,
          "circle-stroke-width": 2,
          "circle-stroke-color": colors.blue["300"],
          "circle-stroke-opacity": hasColorClusters ? 0 : 1,
          "circle-opacity": hasColorClusters ? 0 : 0.7,
          // # clustered points < 15: 24px, # clustered points 15-40: 32px, # clustered points > 40: 40px
          "circle-radius": ["step", ["get", "point_count"], 24, 15, 32, 40, 40],
        },
      })

      // Add a layer showing cluster counts
      if (!hasColorClusters) {
        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "points",
          filter: ["has", "point_count"],
          layout: {
            "text-field": ["get", "point_count_abbreviated"],
          },
        })
      }

      // Add a layer showing individual points when zoomed in enough that they are not clustered
      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "points",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": ["get", "color"],
          // Zoom 1-8: 10px, Zoom 8: 14px, Zoom 9-10: 16px, Zoom >11: 18px
          "circle-radius": ["step", ["zoom"], 10, 8, 14, 9, 16, 11, 18],
          "circle-stroke-width": 2,
          "circle-stroke-color": ["get", "strokeColor"],
          "circle-opacity": 0.7,
          "circle-pitch-scale": "viewport",
        },
      })

      // Zoom in on a cluster when clicked
      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        })
        const clusterId = features[0]?.properties?.cluster_id
        if (clusterId) {
          const source = map.getSource("points") as GeoJSONSource
          source.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return

            map.easeTo({
              center: (features[0].geometry as Point).coordinates as LngLatLike,
              zoom: zoom,
            })
          })
        }
      })

      // Show a popup with a point's popup slot content when an individual point is clicked
      map.on("click", "unclustered-point", (e) => {
        if (hasPopupSlot && e.features && e.features[0]) {
          const feature = e.features[0]
          const coordinates = (feature.geometry as Point).coordinates.slice()

          // Ensure that if the map is zoomed out such that when multiple copies
          // of the feature are visible, the popup appears over the copy being
          // pointed to
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          const metadata = JSON.parse(feature.properties!.metadata)
          const vnode = slots.popup!(metadata)[0]
          const element = document.createElement("div")
          render(vnode, element)
          new mapboxgl.Popup({ closeButton: false })
            .setLngLat(coordinates as LngLatLike)
            .setDOMContent(element)
            .setMaxWidth("25rem")
            .addTo(map)
        }
      })

      // Change the cursor to a pointer when the mouse is over a cluster or individual point
      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer"
      })
      map.on("mouseenter", "unclustered-point", () => {
        if (hasPopupSlot) {
          map.getCanvas().style.cursor = "pointer"
        }
      })

      // Change the cursor back from a pointer when it leaves a cluster or individual point
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = ""
      })
      map.on("mouseleave", "unclustered-point", () => {
        if (hasPopupSlot) {
          map.getCanvas().style.cursor = ""
        }
      })

      // Cache custom cluster markers and keep track of which ones are visible for performance
      let markersCache: Record<string, any> = {}
      let markersOnScreen: Record<string, any> = {}

      // Add/update custom cluster markers after GeoJSON data is loaded
      map.on("render", () => {
        if (map.isSourceLoaded("points") && hasColorClusters) {
          const updates = customizeClusterMarkers({
            map,
            colorClusters,
            markersCache,
            markersOnScreen,
            sourceId: "points",
            clusterMode: props.clusterMode || ClusterMode.donut,
          })
          markersCache = updates.markersCache
          markersOnScreen = updates.markersOnScreen
        }
      })
    })
  }
}

// Render the map on mount
onMounted(() => {
  renderMap()
})

watch([locations], () => {
  renderMap()
})
</script>
