<template>
  <AppPage>
    <AppPageHeader
      :backlink-route="{ name: 'wc-story', params: { storyId: storyId }, query: { procurementOptions: true } }"
      :backlink-text="backlinkText" />
    <div class="wc-page-container text-body-2 -mt-4 w-full">
      <div class="mb-16 mt-4 flex w-full flex-wrap items-start justify-between gap-8 md:flex-nowrap">
        <section class="order-2 mb-8 basis-full rounded-lg bg-lilac-100 px-4 py-8 md:mb-0 md:basis-1/2">
          <div class="border-blue-500">
            <h3 class="text-subheading-large-bold mb-10 px-5">Your procurement</h3>
            <div class="mt-6 flex justify-between px-5">
              <div>From</div>
              <div class="text-right font-bold">{{ storyName }}</div>
            </div>
            <div class="mt-6 flex justify-between px-5">
              <div>Amount</div>
              <div class="text-right font-bold" data-cy="procurement-quantity">
                {{ totalEacs }}<br />
                ({{ totalCarbon }})
              </div>
            </div>
            <div class="flex justify-between px-5 pt-6">
              <div>Total</div>
              <div class="font-bold">
                {{ totalPrice }}
              </div>
            </div>
          </div>
        </section>
        <section
          class="shrink grow basis-full md:basis-1/2"
          :class="{ 'order-1 self-center md:order-3': !store.isLoggedIn, 'order-3 self-start': store.isLoggedIn }">
          <WcPortfolioLogin v-if="!store.isLoggedIn" :callback-url="callbackUrl" class="pb-12" />
          <div v-if="store.isLoggedIn && currentStep === CheckoutStep.PurchaserInformation">
            <h3 class="text-subheading-large-bold mb-4 whitespace-nowrap">Purchaser information</h3>
            <CheckoutPurchaserForm class="mb-10" :email-address="emailAddress" @submit="handleSubmitPurchaserInformation" />
          </div>
          <div v-if="store.isLoggedIn && currentStep === CheckoutStep.PaymentDetails" class="h-full">
            <div class="mb-10 flex flex-col justify-start">
              <h3 class="text-subheading-large-bold mb-4 whitespace-nowrap">Payment details</h3>
              <button class="text-body-2-link flex self-start text-blue-400" @click="currentStep = CheckoutStep.PurchaserInformation">
                Back to Purchaser Information
              </button>
            </div>
            <h4 class="text-subheading-1 mt-10 text-blue-400">Account</h4>
            <div class="text-body-2 mt-2">{{ emailAddress }}</div>
            <h4 class="text-subheading-1 mt-10 text-blue-400">Full legal name</h4>
            <div class="text-body-2 mt-2">{{ selectedAccount?.name }}</div>
            <h4 class="text-subheading-1 mt-10 text-blue-400">Payment method</h4>
            <CheckoutStripeForm class="mt-4" :procurement="marketStore.checkout.procurement" />
          </div>
        </section>
      </div>
    </div>
  </AppPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { useMainStore } from "@/store"
import { useMarketStore } from "./market.state"
import { Account } from "@common/models/models"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import formatCurrencyInPenniesFactory from "@common/utils/formatCurrencyInPenniesFactory"

import { AppPage, AppPageHeader } from "@/components/layout"
import WcPortfolioLogin from "@/modules/portfolio/components/WcPortfolioLogin.vue"
import CheckoutPurchaserForm from "./components/CheckoutPurchaserForm.vue"
import CheckoutStripeForm from "./components/CheckoutStripeForm.vue"

const router = useRouter()
const store = useMainStore()
const marketStore = useMarketStore()
const formatCurrencyInPennies = formatCurrencyInPenniesFactory()

const storyId = computed(() => {
  return marketStore.checkout.story.id ?? 0
})
const storyName = computed(() => {
  return marketStore.checkout.story.name
})
const backlinkText = computed(() => {
  if (storyName.value) {
    return `Back to ${storyName.value}`
  } else {
    return `Back to OpenEAC Exchange`
  }
})
const totalEacs = computed(() => {
  return getFormattedEacQuantity(marketStore.checkout.procurement.totalEacs, EacMeasurementParameter.Electricity)
})
const totalPrice = computed(() => {
  return formatCurrencyInPennies(marketStore.checkout.procurement.pricePenniesUsd)
})
const totalCarbon = computed(() => {
  return getFormattedEacQuantity(marketStore.checkout.procurement.totalGco2, EacMeasurementParameter.CarbonDioxide)
})
const callbackUrl = computed(() => {
  return encodeURIComponent(`/openeac-exchange/listings/checkout`)
})

const emailAddress = ref(store.auth.user?.username || "")
const selectedAccount = ref<Account>({} as Account)

enum CheckoutStep {
  PurchaserInformation,
  PaymentDetails,
}
const currentStep = ref(CheckoutStep.PurchaserInformation)

const handleSubmitPurchaserInformation = (account: Account) => {
  selectedAccount.value = account
  currentStep.value = CheckoutStep.PaymentDetails
}

onMounted(() => {
  if (!marketStore.checkout?.procurement?.listingIds || !marketStore.checkout?.story?.name) {
    router.replace({ name: "wc-story", params: { storyId: storyId.value } })
  }
})
</script>
