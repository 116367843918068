import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteLocation, RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"
import AssetView from "./AssetView.vue"
import RegisterAssetView from "./RegisterAssetView.vue"
import RegisterAssetConfirmationView from "./RegisterAssetConfirmationView.vue"
import ListAssetEacsForSaleView from "./ListAssetEacsForSaleView.vue"
import AssetLayout from "@/modules/asset/AssetLayout.vue"
import UploadAssetTimeSeriesView from "./UploadAssetTimeSeriesView.vue"
import UploadAssetsView from "./UploadAssetsView.vue"
import ReviewAssetTimeSeriesUploadView from "./ReviewAssetTimeSeriesUploadView.vue"
import ReviewAssetUploadView from "./ReviewAssetUploadView.vue"

const assetIdProp = (route: RouteLocation) => ({ assetId: parseInt(scalarFromVectorOrScalar(route.params.assetId)) })

export const assetRoutes: Array<RouteRecordRaw> = [
  {
    path: "/assets",
    name: "wc-assets",
    redirect: { name: "wc-enterprise-assets" },
  },
  {
    path: "/assets/register",
    name: "wc-register-asset",
    component: RegisterAssetView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "Register an asset" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/register/confirmation",
    name: "wc-register-asset-confirmation",
    component: RegisterAssetConfirmationView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "Registration submitted" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/list-eacs-for-sale",
    name: "wc-assets-list-eacs-for-sale",
    component: ListAssetEacsForSaleView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "List asset EACs for sale" },
    props: true,
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/upload",
    name: "wc-assets-upload",
    component: UploadAssetsView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "Upload asset metadata to WEATS" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/review-upload",
    name: "wc-assets-review-upload",
    component: ReviewAssetUploadView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "Review asset upload" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId",
    name: "wc-assets-asset",
    component: AssetView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "Asset Details" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId/upload-timeseries",
    name: "wc-assets-asset-timeseries-upload",
    component: UploadAssetTimeSeriesView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "Upload asset time series data" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId/review-timeseries-upload",
    name: "wc-assets-asset-review-timeseries-upload",
    component: ReviewAssetTimeSeriesUploadView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Enterprise, title: "Upload asset time series data" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/devices/register",
    name: "wc-register-device",
    redirect: { name: "wc-register-asset" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/devices/register/confirmation",
    name: "wc-register-device-confirmation",
    redirect: { name: "wc-register-asset-confirmation" },
    beforeEnter: authorizedRoute,
  },
]
