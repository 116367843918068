<template>
  <a v-if="type === 'anchor'" ref="button" :href="href" :class="outerClasses" :target="openInNewTab ? '_blank' : ''">
    <span :class="innerClasses">
      <slot />
    </span>
  </a>
  <RouterLink v-else-if="type === 'router-link'" :to="routerTo" :class="outerClasses">
    <span :class="innerClasses">
      <slot />
    </span>
  </RouterLink>
  <button v-else-if="type === 'button' || type === 'submit'" ref="button" :class="outerClasses" :type="type" :disabled="isDisabled">
    <span :class="innerClasses">
      <slot />
    </span>
  </button>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { RouterLink } from "vue-router"
import type { RouteLocationRaw } from "vue-router"

import type { Props } from "~/components/v3/ui/ButtonCTA"

const props = withDefaults(defineProps<Props>(), {
  type: "button",
  openInNewTab: false,
  isDisabled: false,
  isFilled: false,
})

const themeClasses = computed(() => {
  if (props.theme === "dark") {
    if (props.isFilled) return "border border-highlight hover:border-blue-300 bg-highlight hover:bg-blue-300 text-blue-500 hover:text-white"
    else return "border border-highlight hover:border-white text-highlight hover:text-white"
  } else if (props.theme === "light" || props.theme === "redesign-light") {
    if (props.isFilled) return "border border-blue-500 hover:border-blue-300 bg-blue-500 hover:bg-blue-300 text-white hover:text-white"
    else return "border border-blue-500 hover:border-blue-300 text-blue-500 hover:text-blue-300"
  } else if (props.theme == "redesign-dark") {
    if (props.isFilled) return "border border-neutral-100 hover:border-white bg-neutral-100 hover:bg-white text-black"
    else return "border border-neutral-100 hover:border-white text-white"
  } else {
    return ""
  }
})

const outerClasses = computed(() => {
  return `inline-flex justify-center items-center flex-col shrink-0 relative ${props.width === "fixed" ? " w-200 md:w-[33.5rem]" : ""}${
    props.width === "fixed-large-mobile" ? " w-[28rem] md:w-[33.5rem]" : ""
  }${props.width === "full" ? " w-full" : ""}${props.isDisabled ? " opacity-25" : ""}`
})

const innerClasses = computed(() => {
  let baseClasses = "rounded flex-1 flex justify-center items-center w-full text-center px-[19px] py-[11px] transition-colors"
  baseClasses += ` ${props.isDisabled ? "" : "cursor-pointer"} ${props.buttonClasses} ${themeClasses.value}`

  if (props.theme?.startsWith("redesign")) {
    return `font-medium ${baseClasses}`
  } else {
    return `tracking-wider ${baseClasses}`
  }
})

const routerTo = computed(() => {
  return props.path as RouteLocationRaw
})
</script>
