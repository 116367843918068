<template>
  <ToolTip class="float-right inline-block" :button-class="`${buttonClass} !size-2.5`" panel-class="bg-white">
    <slot />
  </ToolTip>
</template>

<script setup lang="ts">
import { computed } from "vue"
import ToolTip from "@/components/ui/ToolTip.vue"

const COLOR_MAPPING = {
  primary: "!text-hint !border-hint",
  "primary-dark": "!text-neutral-200 !border-neutral-200",
}

const props = withDefaults(defineProps<{ color?: "primary" | "primary-dark" }>(), {
  color: "primary",
})

const buttonClass = computed(() => COLOR_MAPPING[props.color])
</script>
