<template>
  <div class="flex flex-col">
    <div v-if="supplier !== undefined" class="wc-page-container text-body-2 mt-12 w-full">
      <div class="mb-8 flex justify-start">
        <ButtonArrowCTA
          direction="backward"
          :path="backPath || '/openeac-exchange'"
          button-classes="text-blue-400 fill-blue-400 hover:fill-highlight ph-no-capture"
          @click="captureBackToPortfolioEvent">
          <template v-if="backPath"> Back to Portfolio </template>
          <template v-else> All Portfolios </template>
        </ButtonArrowCTA>
      </div>

      <section class="flex w-full flex-wrap items-start gap-5 md:flex-nowrap">
        <div class="order-2 md:basis-1/2">
          <h2 class="text-heading-4 my-4 md:mt-0">{{ supplier.displayName }}</h2>

          <div class="mb-8">
            <img :src="supplier.logoUrl" alt="" height="70" class="max-h-[70px]" />
          </div>

          <div class="my-6 md:my-8">
            <h4 class="text-subheading-1">About</h4>
            <div class="mt-3">
              <p class="whitespace-pre-line">
                {{ supplier.aboutThisCompany }}
              </p>
            </div>
          </div>
        </div>

        <div class="ph-no-capture order-1 basis-full bg-neutral-200 md:order-3 md:basis-1/2">
          <WcCarousel
            :slides="supplier.images"
            class="h-[240px] w-full bg-neutral-200 md:aspect-square md:h-auto"
            @click="captureSupplierImageClickEvent">
            <template #slide="image">
              <img :alt="image.description" :src="image.url" class="size-full object-cover" />
            </template>
          </WcCarousel>
        </div>
      </section>

      <section class="mb-12 mt-4 grid grid-cols-4 flex-wrap gap-5 md:mt-10 md:grid-cols-10">
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">Project Category</h5>
          <div class="mt-3">{{ formatProjectCategory(supplier.projectCategories[0]) }}</div>
        </div>

        <div class="col-span-6 mt-4">
          <h5 class="text-overline-1">Operating Locations</h5>
          <div class="mt-3">{{ supplier.operatingLocations }}</div>
        </div>
      </section>

      <section v-if="supplier.highlightProjects.length > 0" class="text-body-2 border-t border-neutral-500 py-12">
        <h4 class="text-heading-5 mb-4 text-blue-400">Past Projects</h4>

        <div class="grid w-full grid-cols-1 gap-5 md:grid-cols-3">
          <div v-for="project in supplier.highlightProjects" :key="project.id" class="max-w-[200em]">
            <h5 class="text-heading-6 mb-4">{{ project.name }}</h5>
            <h6 class="text-subheading-2 mb-4 text-blue-400">{{ project.subtitle }}</h6>
            <p>{{ project.description }}</p>
          </div>
        </div>
      </section>

      <section class="wc-homepage-grid text-body-2 border-t border-neutral-500 py-12">
        <div class="col-span-6 md:col-span-9">
          <template v-if="supplier.climateImpacts">
            <h4 class="text-heading-5 mb-4 text-blue-400">Climate Impact</h4>

            <WcMarkdown :source="supplier.climateImpacts" />
          </template>

          <template v-if="supplier.coBenefits">
            <h4 class="text-heading-5 mb-4 mt-10 text-blue-400">Community Impact</h4>

            <WcMarkdown :source="supplier.coBenefits" />
          </template>
        </div>
      </section>

      <div class="ph-no-capture mb-8 flex justify-end">
        <!-- HACK: path="" takes the router to the same page we're on
        but scrollBehavior is set to take us to top! -->
        <ButtonArrowCTA direction="upward" path="" button-classes="text-blue-400 fill-blue-400 hover:fill-highlight" @click="captureGoToTopEvent">
          Go to Top
        </ButtonArrowCTA>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonArrowCTA from "@/components/ui/ButtonArrowCTA.vue"
import WcCarousel from "@/components/ui/WcCarousel.vue"
import WcMarkdown from "@/components/ui/WcMarkdown.vue"
import { formatProjectCategory } from "@common/models/order"
import { ref, computed } from "vue"
import posthog from "posthog-js"
import { Supplier } from "@common/models/supplier"
import { useRoute, useRouter } from "vue-router"
import { useSupplierService } from "@/services/service-container"
import { setPageTitle, setPageDescription, setPageImage } from "@/metatags"

const route = useRoute()
const router = useRouter()
const supplierService = useSupplierService()

// PostHog Events
const captureBackToPortfolioEvent = () => posthog.capture("Clicked on the 'Back to Portfolio' link - Suppliers Page")
const captureGoToTopEvent = () => posthog.capture("Clicked on the 'Go To Top' link - Suppliers Page")
const captureSupplierImageClickEvent = () => posthog.capture("Clicked on 'Supplier Image' slide - Suppliers Page")

const backPath = computed(() => {
  const previousPath = router.options.history.state.back || ""
  if (typeof previousPath === "string" && previousPath.startsWith("/marketplace/portfolios/")) {
    return previousPath as string
  } else {
    return null
  }
})

const fetchSupplier = async () => {
  try {
    return await supplierService.getSupplier(Number(route.params.id))
  } catch (error: any) {
    if (error?.message === "Supplier not found") {
      router.push({ name: "wc-404" })
    } else {
      throw new Error(error)
    }
  }
}

const supplier = ref<Supplier | undefined>(await fetchSupplier())
if (supplier.value !== undefined) {
  setPageTitle(supplier.value.displayName)
  setPageDescription(supplier.value.aboutThisCompany)
  setPageImage(supplier.value.images[0]?.url)
}
</script>
