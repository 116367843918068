<template>
  <Calendar
    v-model="model"
    option-label="label"
    :placeholder="insetLabel"
    :name="name"
    class="w-full"
    :class="inputClass"
    selection-mode="range"
    show-icon
    icon-display="input"
    manual-input
    show-button-bar>
    <template v-if="insetLabel || $slots.presetPanel" #header>
      <div v-if="$slots.presetPanel" class="m-2 w-full">
        <slot name="presetPanel"></slot>
      </div>
      <div v-if="insetLabel && $slots.presetPanel" class="my-3 w-full border-t border-neutral-200" />
      <h4 v-if="insetLabel" class="text-subheading-1 m-2 w-full">{{ insetLabel }}</h4>
    </template>
  </Calendar>
</template>

<script setup lang="ts">
import Calendar from "primevue/calendar"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

const model = defineModel<Date[] | null>()
const props = defineProps<{
  insetLabel?: string
  name: string
  size?: InputSize
}>()
defineSlots<{ presetPanel?: () => any }>()
const inputClass = useInputClass(props)
</script>

<style lang="scss">
// TODO: Design the calendar component. It's currently using default PrimeVue styles + tailwind

// Set styles on a few PrimeVue elements to make things coherent
.p-datepicker-month {
  margin-right: 7px;
}
.p-datepicker-buttonbar {
  @apply mx-4;
}
</style>
