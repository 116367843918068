import { RouteLocation } from "vue-router"
import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import AccountView from "./AccountView.vue"
import AccountsPortfolioView from "./AccountsPortfolioView.vue"
import AccountsManageView from "./AccountsManageView.vue"
import AccountDetailsView from "./AccountDetailsView.vue"
import PortfolioLayout from "@/modules/portfolio/PortfolioLayout.vue"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"
import AccountsEacPositionsView from "./AccountsEacPositionsView.vue"

const portfolioIdProp = (route: RouteLocation) => ({ portfolioId: parseInt(scalarFromVectorOrScalar(route.params.portfolioId)) })

export const accountsRoutes: Array<RouteRecordRaw> = [
  {
    path: "/accounts",
    name: "wc-accounts-dashboard",
    component: AccountView,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise, title: "Accounts" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/accounts/orders",
    name: "wc-order-history",
    component: AccountView,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise, title: "Order History", defaultTab: "orders" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/accounts/portfolio/:portfolioId",
    name: "wc-portfolio-tracker",
    component: AccountsPortfolioView,
    props: portfolioIdProp,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise, title: "Portfolio Tracker" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/accounts/manage",
    name: "wc-accounts-manage",
    component: AccountsManageView,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise, title: "Manage Accounts" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/accounts/:id",
    name: "wc-accounts-details",
    component: AccountDetailsView,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise, title: "Account Details" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/accounts/positions",
    name: "wc-accounts-eac-positions",
    component: AccountsEacPositionsView,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise, title: "Account EACs" },
    beforeEnter: authorizedRoute,
  },
]
