<template>
  <!-- Hour grid -->
  <div class="relative -mb-4 overflow-x-scroll pb-4">
    <div
      ref="hourGridField"
      class="hourgrid relative mx-auto mt-12 h-[288px] select-none bg-black"
      :style="{ width: `${dates.length * gridPixelSize}px` }"
      @pointerdown="handleMouseDown"
      @pointermove="handleMouseMove"
      @pointerup="handleMouseUp">
      <Transition name="fade" mode="out-in">
        <div v-if="eacStore.isLoading" class="absolute-centered-text">Loading EACs</div>
        <div v-else-if="eacStore.totalAvailableQuantity === 0" class="absolute-centered-text">
          There are no EACs in this date range.<br />Select another date range above.
        </div>
      </Transition>
      <!-- Vertical Axis -->
      <div class="absolute left-[-25px] top-0 border-b border-neutral-300 bg-white text-blue-400">
        <div
          v-for="hour in hours"
          :key="hour"
          class="h-3 w-[25px] whitespace-nowrap border-t border-neutral-300 pr-0.5 text-right text-[8px] leading-3">
          {{ hour }}
        </div>
      </div>
      <!-- Horizontal Axis -->
      <div class="absolute bottom-full h-12 w-full">
        <div class="mt-4 flex h-8 border-r border-neutral-300 text-blue-400">
          <!-- Flex basis, shrink and grow values ensure that the distance of axis ticks stays consistent -->
          <div v-for="date in dates" :key="date.toString()" class="shrink-1 relative size-3 grow-0 basis-3 text-center text-[8px]">
            <div
              v-if="date.getDate() === 1"
              class="text-caption absolute top-0 h-8 w-32 border-l border-neutral-300 pl-[3px] pt-0 text-left leading-3">
              {{ format(date, "LLLL Y") }}
            </div>
            <div class="mt-5 h-3 w-full border-l border-neutral-300 text-center leading-3">{{ date.getDate() }}</div>
          </div>
        </div>
      </div>
      <!-- Hourgrid Square -->
      <div class="relative">
        <div
          v-for="(balance, dateTime) in eacStore.eacBalances"
          :key="dateTime as string"
          class="absolute size-[12px] cursor-pointer border-l border-t border-blue-500"
          :class="getGridSquareClass(balance)"
          :style="{
            top: `${balance.hourIndex * gridPixelSize}px`,
            left: `${balance.dateIndex * gridPixelSize}px`,
          }"
          :data-datetime="dateTime"></div>
      </div>
    </div>
    <!-- Legend -->
    <div class="relative mt-2 flex justify-center gap-8">
      <div>
        <div class="text-caption flex items-center gap-2 text-blue-400">
          <div class="flex items-center gap-0.5">
            <div class="hourgrid-legend-square -mt-px bg-blue-400"></div>
            /
            <div class="hourgrid-legend-square -mt-px bg-highlight"></div>
          </div>
          Active EACs (Unselected/Selected)
        </div>
      </div>
      <div>
        <div class="text-caption flex items-center gap-2 text-blue-400">
          <div class="flex items-center gap-0.5">
            <div class="hourgrid-legend-square -mt-px bg-neutral-500"></div>
            /
            <div class="hourgrid-legend-square -mt-px bg-neonred"></div>
          </div>
          Retired EACs (Unselected/Selected)
        </div>
      </div>
      <div>
        <div class="text-caption flex items-center gap-1 text-blue-400">
          <div class="hourgrid-legend-square -mt-px bg-black"></div>
          No EACs
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { format } from "date-fns"
import { useEacStore } from "@/modules/accounts/eac.state"

const eacStore = useEacStore()

const gridPixelSize = 12

const hourGridField = ref<HTMLElement | undefined>(undefined)

const hours = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
]

const dates = computed(() => {
  // while the quarter selector is loading, we just display a sample quarter to have a blank grid
  const currentDate = new Date(eacStore.dateRange?.startDate || "2023-01-01T00:00:00")
  const endDate = new Date(eacStore.dateRange?.endDate || "2023-03-31T00:00:00")

  const result = []
  while (currentDate < endDate) {
    result.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return result
})

const getGridSquareClass = (eacDateTime: any) => {
  if (eacDateTime.retired > 0) {
    return eacStore.isDateTimeSelected(eacDateTime.dateTime) ? "bg-neonred" : "bg-neutral-500 hover:bg-neonred"
  } else {
    return eacStore.isDateTimeSelected(eacDateTime.dateTime) ? "bg-highlight" : "bg-blue-400 hover:bg-highlight"
  }
}

class Position {
  x: number
  y: number
  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }
}

let mouseDownPosition: Position | null = null
let mouseHoldPosition: Position | null = null

const handleMouseDown = (event: PointerEvent) => {
  if (hourGridField.value === undefined) return
  const relativePosition = getRelativePosition(event)
  if (!relativePosition) return
  eacStore.resetSelection()
  mouseDownPosition = mouseHoldPosition = relativePosition
  updateSelectionState()
  hourGridField.value.setPointerCapture(event.pointerId)
}

const handleMouseMove = (event: MouseEvent) => {
  if (mouseDownPosition === null) return
  const relativePosition = getRelativePosition(event)
  if (!relativePosition) return
  mouseHoldPosition = new Position(relativePosition.x, relativePosition.y)
  updateSelectionState()
}

const handleMouseUp = (event: PointerEvent) => {
  mouseDownPosition = mouseHoldPosition = null
  hourGridField.value!.releasePointerCapture(event.pointerId)
}

const getRelativePosition = (event: MouseEvent): Position | null => {
  if (!hourGridField.value) return null
  const hourGridFieldRect = hourGridField.value.getBoundingClientRect()
  const relativeX = event.clientX - hourGridFieldRect.left
  const relativeY = event.clientY - hourGridFieldRect.top
  if (relativeX < 0 || relativeX > hourGridFieldRect.width || relativeY < 0 || relativeY > hourGridFieldRect.height) return null
  return new Position(relativeX, relativeY)
}

const getDragRange = () => {
  if (!mouseDownPosition || !mouseHoldPosition) return null
  const minX = Math.min(mouseDownPosition.x, mouseHoldPosition.x)
  const maxX = Math.max(mouseDownPosition.x, mouseHoldPosition.x)
  const minY = Math.min(mouseDownPosition.y, mouseHoldPosition.y)
  const maxY = Math.max(mouseDownPosition.y, mouseHoldPosition.y)
  const minDateIndex = Math.floor(minX / gridPixelSize)
  const maxDateIndex = Math.floor(maxX / gridPixelSize)
  const minHourIndex = Math.floor(minY / gridPixelSize)
  const maxHourIndex = Math.floor(maxY / gridPixelSize)
  return { minDateIndex, maxDateIndex, minHourIndex, maxHourIndex }
}

const updateSelectionState = () => {
  const dragRange = getDragRange()
  if (!dragRange) return
  eacStore.setSelection(dragRange)
}
</script>

<style lang="scss" scoped>
.absolute-centered-text {
  @apply absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-center pointer-events-none;
}

.hourgrid {
  background-size: 12px 12px;
  background-image: linear-gradient(to right, theme("colors.blue.800") 1px, transparent 1px),
    linear-gradient(to bottom, theme("colors.blue.800") 1px, transparent 1px);
}

.hourgrid-legend-square {
  @apply w-[12px] h-[12px];
}
</style>
