<template>
  <header>
    <div v-if="showAccountContext" class="wc-page-container -mb-8 flex justify-end pt-3">
      <div class="text-body-3 text-neutral-500" data-cy="logged-in-as-message">Logged in as {{ store.account.name }}</div>
    </div>
    <div class="wc-page-container w-full py-12">
      <nav v-if="backlinkText && (backlinkRoute || backlinkHref)" class="-mt-6 mb-3 flex justify-start">
        <WcRouterLink
          :route="backlinkRoute"
          :href="backlinkHref"
          is-back-link
          class="ph-no-capture text-body-3 flex items-center justify-center gap-2 fill-blue-400 py-2 text-blue-400 transition-colors hover:fill-highlight">
          <WcCTA :text="backlinkText" icon="wc-carbon:arrow-left" size="small" />
        </WcRouterLink>
      </nav>
      <div v-if="pageTypeLabel" class="text-overline-1 pb-4">{{ pageTypeLabel }}</div>
      <div class="flex flex-wrap items-baseline justify-between gap-4">
        <h1 class="text-heading-4">
          <slot></slot>
        </h1>
        <slot name="title-adjacent"></slot>
      </div>
      <div v-if="$slots.description" class="wc-homepage-grid text-body-1 mt-3">
        <p class="col-span-full max-w-[762px]">
          <slot name="description"></slot>
        </p>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// define props
import { WcCTA } from "@/components/button"
import WcRouterLink from "@/components/WcRouterLink.vue"
import { useMainStore } from "@/store"

const store = useMainStore()
defineProps({
  pageTypeLabel: { type: String },
  backlinkHref: { type: String },
  backlinkRoute: { type: Object },
  backlinkText: { type: String },
  showAccountContext: { type: Boolean },
})
</script>
