<template>
  <div class="flex w-60 items-center gap-2 sm:justify-end">
    <label>Date Range</label>
    <Listbox v-model="selectedQuarter" :disabled="isLoading" class="w-36" @update:model-value="selectQuarter">
      <div class="text-body-2 relative">
        <ListboxButton
          class="focus-visible:ring-2/75 relative w-full cursor-pointer rounded-md border border-blue-500 bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-highlight focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-highlight">
          <span class="block truncate">
            <template v-if="!!selectedQuarter">{{ selectedQuarter.description }}</template>
            <template v-else>Loading...</template>
          </span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronIcon class="text-gray-400 size-5" aria-hidden="true" />
          </span>
        </ListboxButton>
        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions
            class="text-body-3 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
            <ListboxOption v-for="quarter in quarters" v-slot="{ selected }" :key="quarter.description" :value="quarter" as="template">
              <li class="relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-neutral-200">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ quarter.description }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3"><Icon icon="wc-carbon:checkmark" /></span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue"
import ChevronIcon from "@/components/icon/ChevronIcon.vue"
import { useEacStore } from "@/modules/accounts/eac.state"
import { LocationQueryRaw, useRouter } from "vue-router"
import { ref, watchPostEffect } from "vue"
import { useRegistryService } from "@/services/service-container"
import type { Quarter } from "./QuarterSelector.utils"
import { constructAvailableEacQuarters } from "./QuarterSelector.utils"

const props = defineProps({
  defaultQuarter: { type: String },
})

const router = useRouter()
const eacStore = useEacStore()

const registryService = useRegistryService()

const quarters = ref<Quarter[]>([])
const selectedQuarter = ref<Quarter | null>(null)
const isLoading = ref<boolean>(false)

function replaceUrlParams(params: LocationQueryRaw) {
  const resolvedLocation = router.resolve({
    path: router.currentRoute.value.path,
    query: params,
  })
  history.replaceState({}, "", resolvedLocation.href)
}

watchPostEffect(async () => {
  isLoading.value = true

  const dateRange = await registryService.getBalanceDateRange()
  const availableQuarters = constructAvailableEacQuarters(dateRange)
  quarters.value = availableQuarters

  if (props.defaultQuarter) {
    const paramSelectedQuarter = availableQuarters.find((obj) => obj.description === props.defaultQuarter)
    if (paramSelectedQuarter) {
      selectQuarter(paramSelectedQuarter)
    } else {
      selectQuarter(availableQuarters[0])
    }
  } else {
    selectQuarter(availableQuarters[0])
  }
  isLoading.value = false
})

const selectQuarter = (quarter: Quarter) => {
  selectedQuarter.value = quarter
  replaceUrlParams({ quarter: quarter.description })
  eacStore.setDateRange(quarter.dateRange)
}
</script>
