<template>
  <div :id="chartId" class="bg-white dark:bg-blue-800"></div>
</template>

<script lang="ts" setup>
// Libraries
import { onMounted, PropType, watch, reactive, ref } from "vue"
import { format } from "date-fns"
import { EChartsOption, EChartsType } from "echarts"
import * as echarts from "echarts"

// Models
import { colors } from "@common/global"
import { DashboardGraphStackData, formatNumericalFigure } from "../carbonAccountingDashboard.helper"

// Services
import { useCarbonAccountingDashboardStore } from "../carbonAccountingDashboard.state"
import { useMainStore } from "@/store"

const props = defineProps({
  height: { type: String },
  width: { type: String },
  data: { type: Object as PropType<DashboardGraphStackData>, required: true },
  isMarket: { type: Boolean, default: false },
})

const store = useMainStore()
const dashboardStore = useCarbonAccountingDashboardStore()

const chartId = `dashboard_CFE_${new Date().getTime()}`

const graphColors = reactive({
  text: store.colors.text,
  highlight: store.colors.highlight,
  bg: store.colors.background,
  carbon: store.colors.carbonBased,
  carbonDecal: store.colors.carbonBasedLight,
  locationalCarbonFree: store.colors.carbonFreeLocational,
  locationalCarbonFreeDecal: store.colors.carbonFreeLocationalLight,
  marketCarbonFree: store.colors.carbonFreeMarket,
  marketCarbonFreeDecal: store.colors.carbonFreeMarketLight,
})

const option = reactive<EChartsOption>({
  // ToDo: standardize and import grid options from a shared file
  title: {
    text: "Energy consumption per rolling 12-month period",
    textAlign: "center",
    textStyle: {
      fontWeight: "bold",
      fontSize: "1rem",
      color: graphColors.text,
    },
    left: "50%",
    padding: 10,
  },
  grid: {
    left: 50,
    top: 40,
    right: 10,
    bottom: 45,
  },
  xAxis: [
    {
      type: "category",
      data: [],
      axisTick: { show: false },
      axisLine: { show: true },
      axisLabel: {
        color: graphColors.text,
        hideOverlap: false,
      },

      triggerEvent: true,
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "MWh",
      scale: false,
      axisLine: { lineStyle: { color: graphColors.text } },
      splitLine: { show: false },
      axisLabel: {
        color: graphColors.text,
        formatter: (value: number) => formatNumericalFigure(value, { isNegativeZero: false, precision: 2, round: true }),
      },
    },
  ],
  series: [
    {
      name: "Net Carbon-based (Forecast)",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.carbon,
        borderColor: graphColors.carbon,
        borderWidth: 1,
        decal: {
          color: graphColors.carbonDecal,
          dashArrayX: [1, 0],
          dashArrayY: [1, 3],
          rotation: -Math.PI / 4,
        },
      },
    },
    {
      name: "Net Carbon-based",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.carbon,
        decal: {
          symbolSize: 0,
        },
      },
    },

    {
      name: "Locational Carbon-free (Forecast)",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.locationalCarbonFree,
        borderColor: graphColors.locationalCarbonFree,
        borderWidth: 1,
        decal: {
          color: graphColors.locationalCarbonFreeDecal,
          dashArrayX: [1, 0],
          dashArrayY: [1, 3],
          rotation: -Math.PI / 4,
        },
      },
    },
    {
      name: "Locational Carbon-free",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.locationalCarbonFree,
        decal: {
          symbolSize: 0,
        },
      },
    },
    {
      name: "Market Carbon-free (Forecast)",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.marketCarbonFree,
        // borderColor: colors.highlight,
        // borderWidth: 1,
        decal: {
          color: graphColors.marketCarbonFreeDecal,
          dashArrayX: [1, 0],
          dashArrayY: [1, 3],
          rotation: -Math.PI / 4,
        },
      },
    },
    {
      name: "Market Carbon-free",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.marketCarbonFree,
        decal: {
          symbolSize: 0,
        },
      },
    },
    {
      name: "Market Carbon-free Production (Forecasted)",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.marketCarbonFree,
        // borderColor: colors.highlight,
        // borderWidth: 1,
        decal: {
          color: graphColors.marketCarbonFreeDecal,
          dashArrayX: [1, 0],
          dashArrayY: [1, 3],
          rotation: -Math.PI / 4,
        },
      },
    },
    {
      name: "Market Carbon-free Production",
      type: "bar",
      stack: "Month",
      data: [],
      itemStyle: {
        color: graphColors.marketCarbonFree,
        decal: {
          symbolSize: 0,
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // Use axis to trigger tooltip
      type: "line", // 'shadow' as default; can also be 'line' or 'shadow'
    },
    formatter: tooltipFormatter,
    confine: true,
  },
  aria: {
    enabled: true,
    decal: {
      show: true,
    },
  },
})

const chart = ref<EChartsType>()

const renderChart = () => {
  if (!chart.value) {
    return
  }
  const hasMarketCarbonFreeProductionActualMWh =
    props.data.marketCarbonFreeProductionActualMWh.reduce((total: number, current: number) => current + total, 0) === 0
  const hasMarketCarbonFreeProductionForecastedMWh =
    props.data.marketCarbonFreeProductionForecastedMWh.reduce((total: number, current: number) => current + total, 0) === 0

  const _option = {
    xAxis: [
      {
        data: props.data.monthLabels,
        axisLabel: {
          color: (value: string, index: number) => {
            const currentDateRange = props.data.dateRanges[index]
            const currentEndDate = currentDateRange[1]
            if (currentEndDate.getTime() === dashboardStore.assetsDateRange.endDate.getTime()) {
              return graphColors.highlight
            }
            return graphColors.text
          },
        },
      },
    ],
    series: [
      {
        name: "Net Carbon-based (Forecast)",
        stack: "Month",
        data: props.data.netCarbonBasedForecastedMWh,
      },
      {
        name: "Net Carbon-based",
        stack: "Month",
        data: props.data.netCarbonBasedActualMWh,
      },
      {
        name: "Locational Carbon-free (Forecast)",
        stack: "Month",
        data: props.data.locationalCarbonFreeForecastedMWh,
      },
      {
        name: "Locational Carbon-free",
        stack: "Month",
        data: props.data.locationalCarbonFreeActualMWh,
      },
      {
        name: "Market Carbon-free",
        stack: "Month",
        data: props.data.marketCarbonFreeActualMWh,
      },
      {
        name: "Market Carbon-free (Forecast)",
        stack: "Month",
        data: props.data.marketCarbonFreeForecastedMWh,
      },
      {
        name: "Market Carbon-free Production",
        stack: "Month",
        data: hasMarketCarbonFreeProductionActualMWh ? props.data.marketCarbonFreeProductionActualMWh : [],
      },
      {
        name: "Market Carbon-free Production (Forecast)",
        stack: "Month",
        data: hasMarketCarbonFreeProductionForecastedMWh ? props.data.marketCarbonFreeProductionForecastedMWh : [],
      },
    ],
  }
  chart.value.setOption(_option)
}

let highlightedXAxisLabel = -1
const highlightXAxisLabel = (index: number) => {
  highlightedXAxisLabel = index
}
const unHighlightXAxisLabel = () => {
  highlightedXAxisLabel = -1
}
const renderChartAxis = () => {
  if (!chart.value) {
    return
  }
  chart.value.setOption({
    xAxis: [
      {
        axisLabel: {
          color: (value: string, index: number) => {
            const currentDateRange = props.data.dateRanges[index]
            const currentEndDate = currentDateRange[1]
            if (currentEndDate.getTime() === dashboardStore.assetsDateRange.endDate.getTime()) {
              return graphColors.highlight
            }
            if (highlightedXAxisLabel === index) {
              return graphColors.highlight
            }
            return graphColors.text
          },
        },
      },
    ],
  })
}

onMounted(() => {
  const chartDom = document.getElementById(chartId)
  if (chartDom === null) return
  const _chart = echarts.init(chartDom, undefined, {
    width: props.width,
    height: props.height,
  })
  option && _chart.setOption(option)
  // renderChart()

  new ResizeObserver(() => _chart.resize()).observe(chartDom)

  // Click to update assets date range
  _chart.on("click", (params: any) => {
    const dataIndex = params.dataIndex
    if (dataIndex === undefined) return
    const selectedDateRange = props.data.dateRanges[dataIndex]
    dashboardStore.assetsDateRange.startDate = selectedDateRange[0]
    dashboardStore.assetsDateRange.endDate = selectedDateRange[1]
  })

  _chart.on("mouseover", (params: any) => {
    const dataIndex = params.dataIndex
    if (dataIndex === undefined) return
    highlightXAxisLabel(dataIndex)
    renderChartAxis()
  })

  _chart.on("mouseout", (params: any) => {
    const dataIndex = params.dataIndex
    if (dataIndex === undefined) return
    unHighlightXAxisLabel()
    renderChartAxis()
  })

  chart.value = _chart
  renderChart()
})

watch(
  () => props.data,
  () => {
    renderChart()
  }
)

watch(
  () => dashboardStore.assetsDateRange,
  () => {
    renderChartAxis()
  },
  { deep: true }
)

/* Saving this stuff in case we need to do custom chart markers again. */
// function getTooltipMarkerHtml(color: string, dashColor: string | undefined = undefined) {
//   if (dashColor === undefined) {
//     return `<div class="h-5 w-8 ml-[1px]" style="background: ${color}"></div>`
//   } else {
//     const gradient = `repeating-linear-gradient(-45deg, ${dashColor} 0 1px, ${color} 1px 4px)`
//     return `<div class="h-5 w-8 ml-[1px]" style="background: ${gradient}; border: 1px solid ${color};"></div>`
//   }
// }

function getTooltipMarkerHtmlMixed(color: string, dashColor: string | undefined = undefined) {
  if (dashColor === undefined) {
    return `<div class="h-5 w-8" style="background: ${color}"></div>`
  } else {
    const gradient = `repeating-linear-gradient(-45deg, ${dashColor} 0 1px, ${color} 1px 4px)`
    return `
      <div class="h-2.5 w-8" style="background: ${color}"></div>
      <div class="h-2.5 w-8" style="background: ${gradient};"></div>
    `
  }
}

// function getTooltipRowHtml(
//   rowTitle: string,
//   dataValue: number,
//   dataUnit: string,
//   markerColor: string,
//   markerDashColor: string | undefined = undefined
// ) {
//   if (dataValue === 0) {
//     return ""
//   } else {
//     return `
//     <tr class="tt-row text-neutral-500">
//       <td class="flex items-center p-0">
//         ${getTooltipMarkerHtml(markerColor, markerDashColor)}
//         <div>${rowTitle}</div>
//       </td>
//       <td class="text-right p-0">${formatNumericalFigure(dataValue)} ${dataUnit}</td>
//     </tr>`
//   }
// }

function tooltipFormatter(params: any) {
  const dataIndex = params[0]?.dataIndex
  const dateRange = props.data.dateRanges[dataIndex]
  const dateFormat = "LLL d, y"
  const dateRangeFormatted = `${format(dateRange[0], dateFormat)} – ${format(dateRange[1], dateFormat)}`
  const locationalCarbonFreeActual = params.find((item: any) => item.seriesName === "Locational Carbon-free").value || 0
  const locationalCarbonFreeForecasted = params.find((item: any) => item.seriesName === "Locational Carbon-free (Forecast)").value || 0
  const locationalCarbonFreeTotal = formatNumericalFigure(locationalCarbonFreeActual + locationalCarbonFreeForecasted)
  const marketCarbonFreeActual = params.find((item: any) => item.seriesName === "Market Carbon-free").value || 0
  const marketCarbonFreeForecasted = params.find((item: any) => item.seriesName === "Market Carbon-free (Forecast)").value || 0
  const marketCarbonFreeTotal = formatNumericalFigure(marketCarbonFreeActual + marketCarbonFreeForecasted)
  const netCarbonBasedActual = params.find((item: any) => item.seriesName === "Net Carbon-based").value || 0
  const netCarbonBasedForecasted = params.find((item: any) => item.seriesName === "Net Carbon-based (Forecast)").value || 0
  const netCarbonBasedTotal = formatNumericalFigure(netCarbonBasedActual + netCarbonBasedForecasted)
  const marketCarbonFreeProductionActual = params.find((item: any) => item.seriesName === "Market Carbon-free Production")?.value || 0
  const marketCarbonFreeProductionForecasted = params.find((item: any) => item.seriesName === "Market Carbon-free Production (Forecast)")?.value || 0
  const marketCarbonFreeProductionTotal = formatNumericalFigure(marketCarbonFreeProductionActual + marketCarbonFreeProductionForecasted)
  const marketCarbonFreeProductionHtml =
    marketCarbonFreeProductionTotal !== "0"
      ? `
  <div class="mt-2 pt-2 border-t border-neutral-300 font-semibold text-neutral-500">PRODUCTION</div>
  <table class="border-spacing-0">
    <tr class="tt-row">
      <td class="pr-0">${getTooltipMarkerHtmlMixed(
        colors.highlight,
        marketCarbonFreeProductionForecasted !== 0 ? colors.blue["100"] : undefined
      )}</td>
      <th class="font-normal text-left px-0 pl-1 pt-2">Market Carbon-free${marketCarbonFreeProductionForecasted !== 0 ? "*" : ""}</th>
      <td class="text-right pt-2 pl-2">${marketCarbonFreeProductionTotal} MWh</td>
    </tr>
  </table>
  `
      : ""

  const isForecastDisclaimerNeeded =
    locationalCarbonFreeForecasted !== 0 ||
    marketCarbonFreeForecasted !== 0 ||
    netCarbonBasedForecasted !== 0 ||
    marketCarbonFreeProductionForecasted !== 0
  const disclaimerMarkerGradient = `repeating-linear-gradient(-45deg, ${colors.neutral["500"]} 0 1px, ${graphColors.carbon} 1px 4px)`
  const forecastDisclaimerHtml = isForecastDisclaimerNeeded
    ? `
  <div class="mt-2 -mb-1 pt-2 border-t border-neutral-300 text-neutral-500 text-xs">
    <div class="inline-block h-2.5 w-8 ml-[1px] mr-1" style="background: ${disclaimerMarkerGradient};"></div>
    *Calculated from forecasted estimates.
  </div>
  `
    : ""

  return `
  <div class="dashboard-chart-tooltip w-[270px] text-sm">
    <div class="pb-2 font-semibold ">${dateRangeFormatted}</div>
    <div class="pt-2 border-t border-neutral-300 font-semibold text-neutral-500">CONSUMPTION</div>
    <table class="border-spacing-0">
      <tr class="tt-row">
        <td class="pr-0">${getTooltipMarkerHtmlMixed(colors.blue["200"], locationalCarbonFreeForecasted !== 0 ? colors.white : undefined)}</td>
        <th class="font-normal text-left px-0 pl-1 pt-2">Locational Carbon-free${locationalCarbonFreeForecasted !== 0 ? "*" : ""}</th>
        <td class="text-right pt-2 pl-2">${locationalCarbonFreeTotal} MWh</td>
      </tr>
      <tr class="tt-row">
        <td class="pr-0">${getTooltipMarkerHtmlMixed(colors.highlight, marketCarbonFreeForecasted !== 0 ? colors.blue["100"] : undefined)}</td>
        <th class="font-normal text-left px-0 pl-1 pt-2">Market Carbon-free${marketCarbonFreeForecasted !== 0 ? "*" : ""}</th>
        <td class="text-right pt-2 pl-2">${marketCarbonFreeTotal} MWh</td>
      </tr>
      <tr class="tt-row">
        <td class="pr-0">${getTooltipMarkerHtmlMixed(graphColors.carbon, marketCarbonFreeForecasted !== 0 ? colors.neutral["500"] : undefined)}</td>
        <th class="font-normal text-left px-0 pl-1 pt-2">Net Carbon-based${marketCarbonFreeForecasted !== 0 ? "*" : ""}</th>
        <td class="text-right pt-2 pl-2">${netCarbonBasedTotal} MWh</td>
      </tr>
    </table>
  </div>
  ${marketCarbonFreeProductionHtml}
  ${forecastDisclaimerHtml}
  `
}
</script>
