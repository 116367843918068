<template>
  <AppPageSection>
    <AppPageSectionHeader>Activity Log</AppPageSectionHeader>
    <div id="transactions" class="mt-12 overflow-x-scroll">
      <AccountTransactionsTable :include-price-info="false" :kind="INCLUDED_KINDS" :summary="false" />
    </div>
  </AppPageSection>
</template>

<script setup lang="ts">
import { AppPageSection, AppPageSectionHeader } from "@/components/layout"
import AccountTransactionsTable from "@/modules/accounts/components/AccountTransactionsTable.vue"

const INCLUDED_KINDS = ["listing_sale", "minting", "purchase", "retirement", "transfer"]
</script>
