import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import PortfolioLayout from "@/modules/portfolio/PortfolioLayout.vue"
import EnterpriseManageEACs from "@/modules/eacs/EnterpriseManageEACs.vue"
import EnterpriseManageEACsDetail from "@/modules/eacs/EnterpriseManageEACsDetail.vue"
import EnterpriseTransactions from "@/modules/eacs/EnterpriseTransactions.vue"

export const eacRoutes: Array<RouteRecordRaw> = [
  {
    path: "/eacs",
    name: "wc-eacs",
    component: EnterpriseManageEACs,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise },
    redirect: { name: "wc-eacs-select-eacs" },
    beforeEnter: authorizedRoute,
    children: [
      { name: "wc-eacs-select-eacs", path: "./", component: EnterpriseManageEACsDetail },
      { name: "wc-eacs-transactions", path: "transactions", component: EnterpriseTransactions },
    ],
  },
]
