import { defineStore } from "pinia"
import { ref } from "vue"

export const useCustomOrderStore = defineStore("custom-order", () => {

  const isVisible = ref<boolean>(false)
  
  return {
    isVisible,

    show: () => {
      isVisible.value = true
    },

    hide: () => {
      isVisible.value = false
    },
  }
})
