<template>
  <div class="text-caption flex w-full justify-end gap-4">
    <div class="flex items-center">
      <span class="eac-grid-legend-square -mt-px bg-eac-grid-active-unselected" />
      |
      <span class="eac-grid-legend-square -mt-px bg-accent" />
      Active EACs (Unselected | Selected)
    </div>
    <div class="flex items-center">
      <span class="eac-grid-legend-square -mt-px bg-neonred-300" />
      |
      <span class="eac-grid-legend-square -mt-px bg-neonred" />
      Retired EACs (Unselected | Selected)
    </div>
    <div class="flex items-center">
      <span class="eac-grid-legend-square -mt-px bg-eac-grid-background" />
      No EACs
    </div>
  </div>
</template>

<style lang="scss" scoped>
.eac-grid {
  background-size: 12px 12px;
  background-image: linear-gradient(to right, theme("colors.blue.800") 1px, transparent 1px),
    linear-gradient(to bottom, theme("colors.blue.800") 1px, transparent 1px);
}

.eac-grid-legend-square {
  @apply w-[12px] h-[12px] mx-1;
}
</style>
