<template>
  <WcTable
    :data="assetsData"
    :columns="[
      { key: 'id', label: 'Asset ID', align: 'left', thClass: 'w-2/12' },
      { key: 'updatedDate', label: 'Last Update', align: 'left', thClass: 'w-2/12' },
      { key: 'status', label: 'Status', align: 'left', thClass: 'w-2/12' },
      { key: 'location', label: 'Location', align: 'left', thClass: 'w-2/12' },
      ...(showPrice ? [{ key: 'price', label: 'Price', align: 'right', thClass: 'w-2/12' } as TableHeader] : []),
      ...(showKind ? [{ key: 'kind', label: 'Resource type', align: 'left', thClass: 'w-2/12' } as TableHeader] : []),
    ]"
    row-id-field="id"
    table-class="text-body-3 min-w-main-content table-fixed"
    tr-class="cursor-pointer hover:bg-neutral-100 transition-colors border-b border-neutral-200"
    th-class="text-left py-2"
    td-class="p-3"
    :sortable="true">
    <template #status="{ row }: { row: AssetRow }">
      <div class="flex items-center">
        <div
          class="-my-1 rounded px-3 py-1"
          :class="{
            'bg-[#c7e5fd]': row.status === AssetStatus.approved,
            'bg-[#ffe1bd]': row.status === AssetStatus.pending,
            'bg-[#ffc8cc]': row.status === AssetStatus.changes_requested,
            'bg-neutral-100': row.status === AssetStatus.draft,
          }">
          {{ row.statusSummary }}
        </div>
        <ToolTip v-if="row.reviewNotes" class="whitespace-normal">
          <div>{{ row.reviewNotes }}</div>
        </ToolTip>
      </div>
    </template>
  </WcTable>
</template>

<script setup lang="ts">
import { computed } from "vue"
import ToolTip from "@/components/ui/ToolTip.vue"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import type { Asset } from "@common/models/asset"
import { ASSET_KIND, ASSET_STATUS, AssetStatus, getAssetPrice } from "@common/models/asset"

type AssetRow = {
  id: number
  kind: string
  location: string
  price: string
  reviewNotes?: string
  status: AssetStatus
  statusSummary: string
  updatedDate: string
}

const props = defineProps<{ assets: Asset[]; showKind?: boolean; showPrice?: boolean }>()

const assetsData = computed<AssetRow[]>(() => {
  return props.assets.map((asset) => {
    return {
      id: asset.id,
      kind: ASSET_KIND[asset.kind] || asset.kind,
      location: `${asset.city}, ${asset.state}`,
      price: getAssetPrice(asset),
      reviewNotes: asset.reviewNotes,
      status: asset.status,
      statusSummary: ASSET_STATUS[asset.status].summary || asset.status,
      updatedDate: new Date(asset.updatedTime).toISOString().split("T")[0],
    }
  })
})
</script>
