<template>
  <footer class="wc-footer" :class="{ 'dark bg-blue-500': theme === 'dark', 'light bg-secondary-background': theme === 'light' }">
    <nav class="wc-page-container pt-8 lg:pt-16" :class="{ 'text-white': theme === 'dark' }">
      <div id="footer-grid" class="flex w-full flex-col flex-wrap gap-5 pb-4 md:flex-row lg:grid">
        <div class="border-divider-light basis-full border-b pb-6 lg:border-b-0 lg:pb-0">
          <a
            href="https://www.wattcarbon.com"
            class="logo ph-no-capture relative top-[-5px] block h-[32px] w-[168px] shrink-0 bg-contain text-[transparent]"
            :class="{ dark: theme === 'dark' }"
            @click="captureHomeEvent"
            >WattCarbon</a
          >
        </div>
        <div class="mb-3 flex flex-auto flex-col items-start gap-4 md:col-span-1 lg:mb-0">
          <h5 class="text-overline-1 lg:mb-4">Solutions</h5>
          <ButtonNav
            :theme="theme"
            button-classes="ml-4 md:ml-0 ph-no-capture"
            type="anchor"
            :href="`${wwwUrl}/products/procurement#open-eac-exchange`"
            @click="captureMarketEvent"
            >OpenEAC&nbsp;Exchange</ButtonNav
          >
          <ButtonNav
            :theme="theme"
            button-classes="ml-4 md:ml-0 ph-no-capture"
            type="anchor"
            :href="`${wwwUrl}/products/procurement#finance`"
            @click="captureSellEacsEvent"
            >vPPA Financing</ButtonNav
          >
          <ButtonNav
            :theme="theme"
            button-classes="ml-4 md:ml-0 ph-no-capture"
            type="anchor"
            :href="`${wwwUrl}/products/weats-pro`"
            @click="captureCfxEvent"
            >WEATS Pro</ButtonNav
          >
        </div>
        <div class="mb-3 flex flex-auto flex-col items-start gap-4 md:col-span-1 lg:mb-0">
          <h5 class="text-overline-1 lg:mb-4">Resources</h5>
          <ButtonNav :theme="theme" button-classes="ml-4 md:ml-0 ph-no-capture" type="anchor" :href="`${wwwUrl}/weats`" @click="captureWEATSEvent"
            >WEATS</ButtonNav
          >
          <ButtonNav :theme="theme" button-classes="ml-4 md:ml-0 ph-no-capture" type="anchor" :href="`${wwwUrl}/faq`" @click="captureFAQEvent"
            >FAQs</ButtonNav
          >
          <ButtonNav
            :theme="theme"
            button-classes="ml-4 md:ml-0 ph-no-capture"
            type="anchor"
            href="https://blog.wattcarbon.com/archive"
            open-in-new-tab="true"
            @click="captureBlogEvent"
            >Blog</ButtonNav
          >
        </div>
        <div class="mb-3 flex flex-auto flex-col items-start gap-4 md:col-span-1 lg:mb-0">
          <h5 class="text-overline-1 lg:mb-4">Connect</h5>
          <ButtonNav
            :theme="theme"
            button-classes="ml-4 md:ml-0 ph-no-capture"
            type="anchor"
            href="https://www.linkedin.com/company/wattcarbon/"
            open-in-new-tab="true"
            @click="captureLinkedinEvent"
            >LinkedIn</ButtonNav
          >
          <ButtonNav
            :theme="theme"
            button-classes="ml-4 md:ml-0 ph-no-capture"
            type="anchor"
            href="https://climatebase.org/company/1132206/wattcarbon"
            open-in-new-tab="true"
            @click="captureJobsEvent"
            >Jobs</ButtonNav
          >
          <ButtonNav :theme="theme" button-classes="ml-4 md:ml-0 ph-no-capture" type="button" @click="openContactForm">Contact Us</ButtonNav>
        </div>
        <section
          class="flex flex-auto flex-col items-stretch rounded-lg p-6 pt-4 lg:-mt-4"
          :class="{
            'bg-blue-800 text-white': theme === 'dark',
            'bg-neutral-100 text-black': theme === 'light',
          }">
          <h3 class="text-subheading-2 mb-6">Sign up for our blog to stay connected on WattCarbon updates.</h3>

          <input
            id="email"
            v-model="email"
            type="email"
            placeholder="Email"
            class="mb-6 w-full rounded border bg-transparent !px-[15px] !py-[7px] text-white"
            style="border-color: #c3c6cb; color: white" />

          <WcButton variant="contained" size="medium" text="Sign Up" @click="onSubmit" />
        </section>
      </div>
      <div class="text-caption mt-8 border-[#B4BEC0] pb-8 pt-4 lg:-mx-4 lg:border-t">
        <span class="mb-6 block lg:mb-0 lg:inline-block">
          Copyright © 2024 WattCarbon.
          <span class="inline-block">All rights reserved.</span>
        </span>
        <a class="ph-no-capture inline-block no-underline lg:ml-6" :href="`${wwwUrl}/terms`" @click="captureTermsEvent">Terms & conditions</a>
        <a class="ph-no-capture ml-6 inline-block no-underline" :href="`${wwwUrl}/privacy-policy`" @click="capturePrivacyPolicyEvent"
          >Privacy Policy</a
        >
      </div>
    </nav>
  </footer>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import posthog from "posthog-js"
import { WcButton } from "@/components/button"
import ButtonNav from "@/components/ui/ButtonNav.vue"
import { getEnvironment } from "@/environment"

const { WWW_BASE_URL } = getEnvironment()

defineProps<{ theme: "light" | "dark" }>()

const captureContactUsEvent = () => posthog.capture("Clicked on the 'Contact Us' button - Footer")
const captureHomeEvent = () => posthog.capture("Clicked on the 'Home' link - Footer")
const captureMarketEvent = () => posthog.capture("Clicked on the 'Market' link - Footer")
const captureSellEacsEvent = () => posthog.capture("Clicked on the 'Sell EACs' link - Footer")
const captureCfxEvent = () => posthog.capture("Clicked on the 'WattCarbon Plus' link - Footer")
const captureFAQEvent = () => posthog.capture("Clicked on the 'FAQ' link - Footer")
const captureWEATSEvent = () => posthog.capture("Clicked on the 'WEATS' link - Footer")
const captureBlogEvent = () => posthog.capture("Clicked on the 'Blog' link - Footer")
const captureLinkedinEvent = () => posthog.capture("Clicked on the 'LinkedIn' link - Footer")
const captureJobsEvent = () => posthog.capture("Clicked on the 'Jobs' link - Footer")
const captureTermsEvent = () => posthog.capture("Clicked on the 'Terms & conditions' link - Footer")
const capturePrivacyPolicyEvent = () => posthog.capture("Clicked on the 'Privacy Policy' link - Footer")

const { API_BASE_URL } = getEnvironment()

const emit = defineEmits(["openContactForm"])

const email = ref("")

const error = ref("")
const result = ref(false)

const onSubmit = async () => {
  error.value = ""
  result.value = false
  try {
    const body = {
      name: "",
      company: "",
      email: email.value ?? "",
      message: "Sign me up for the blog!",
    }
    await fetch(`${API_BASE_URL}/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    result.value = true
  } catch (ex) {
    console.error(ex)
    error.value = "Sorry, we're having an issue with our form. Please follow the link to sign up."
  }
}

const openContactForm = () => {
  emit("openContactForm")
  captureContactUsEvent()
}

const wwwUrl = ref(WWW_BASE_URL)
</script>

<style scoped>
.wc-footer a.logo {
  background: url("@/static/img/WattCarbon_logo_05172023.svg") no-repeat;
}

.wc-footer.light a.logo {
  fill: black;
  background: url("@/static/img/logo-dark.svg") no-repeat;
}

#footer-grid {
  grid-template-columns: minmax(170px, 316px) repeat(3, minmax(140px, 1fr)) minmax(200px, 315px);
}
</style>
