<template>
  <AppPage>
    <AppPageHeader page-type-label="Portfolio" show-account-context>
      {{ orderDetail?.orderSummary?.portfolio.name }}
      <template #title-adjacent>
        <ButtonCTA
          class="hidden self-center md:block"
          type="router-link"
          :path="marketplacePortfolioPath"
          theme="light"
          :is-filled="false"
          target="_blank"
          @click="captureViewInMarketplaceEvent"
          >View in Marketplace</ButtonCTA
        >
        <router-link
          class="text-hyperlink text-body-3 inline-block md:hidden"
          :to="marketplacePortfolioPath"
          target="_blank"
          @click="captureViewInMarketplaceEvent"
          >View in Marketplace</router-link
        >
      </template>
    </AppPageHeader>
    <div class="wc-page-container w-full">
      <div class="my-12 flex gap-8 border-b border-neutral-500 pb-12">
        <div>
          <h4 class="text-overline-1">Status</h4>
          <div class="text-body-2 mt-3 capitalize" data-cy="deployment-timeline">{{ orderDetail?.orderSummary.portfolio.status }}</div>
        </div>
        <div>
          <h4 class="text-overline-1">Deployment Timeline</h4>
          <div class="text-body-2 mt-3">{{ orderDetail?.orderSummary.portfolio.dateRangeDescription }}</div>
        </div>
      </div>
    </div>
    <div class="wc-page-container mt-8">
      <h3 class="text-subheading-large-bold mb-10">Orders</h3>
      <AccountsOrdersTable :portfolio-id="portfolioId" class="overflow-x-scroll"></AccountsOrdersTable>
    </div>
    <section class="my-14 w-full bg-blue-100 py-10">
      <div class="wc-page-container">
        <h3 class="text-subheading-large-bold">Energy Attribute Certificates</h3>
        <div class="wc-homepage-grid mt-6 md:mt-8">
          <div class="col-span-5 md:mb-3">
            <p>
              Figures shown on this page reflect Energy Attribute Certificates (EACs) allocated to you based on your contribution to the portfolio.
            </p>
          </div>
          <div class="col-span-3 mt-1 md:col-start-7">
            <h4 class="text-overline-1">Purchased Amount</h4>
            <div class="text-body-1 mt-3">
              <span data-cy="purchased-amount-number">
                {{ orderDetail?.orderSummary.quantityTotal }}
              </span>
              {{ unit }}
            </div>
          </div>
          <div class="col-span-3 mt-1">
            <h4 class="text-overline-1">Allocated Amount</h4>
            <div class="text-body-1 mt-3">{{ totalAllocatedShorthand }}</div>
          </div>
        </div>
        <div class="ph-no-capture mt-8 flex w-full flex-col gap-2 sm:flex-row">
          <ButtonCTA class="items-stretch" theme="light" :is-filled="false" @click="isShareableEACVisible = true">View Certificate</ButtonCTA>
        </div>
        <ShareableEAC
          v-if="isShareableEACVisible"
          ref="shareableEAC"
          :account-name="orderDetail?.orderSummary?.accountName"
          :total-amount="totalAllocated || 0"
          :carbon-value="orderDetail?.orderSummary?.carbonValueG || 0"
          :portfolio-name="orderDetail?.orderSummary?.portfolio.name"
          :resource-type="overallAssetKind"
          :start-date="format(parseISO(orderDetail?.orderSummary?.portfolio.dateRange.lower), 'LLLL d, y')"
          :updated-time="format(parseISO(orderDetail?.orderSummary?.updatedTime || new Date().toISOString()), 'LLLL d, y')"
          :locations="allAssetStates"
          :units="orderDetail.orderSummary.portfolio.units"
          :balancing-authority="orderDetail?.orderSummary?.portfolio.provider || ''"
          @close="isShareableEACVisible = false"
          @clicked-review-eacs="handleClickReviewEacs()" />
      </div>
    </section>

    <section class="wc-page-container">
      <h3 class="text-subheading-large-bold">Assets</h3>
      <div v-if="assetsBySupplierId.size">
        <div
          v-for="[supplierId, assetList] in assetsBySupplierId"
          :key="supplierId"
          class="wc-homepage-grid mt-10 border-neutral-300 pb-6 [&:not(:last-child)]:border-b">
          <div class="col-span-4">
            <router-link
              v-if="suppliers.get(supplierId).isListedOnMarketplace"
              :to="`/suppliers/${supplierId}/${suppliers.get(supplierId).slug}`"
              target="_blank">
              <h4 class="text-subheading-1">{{ suppliers.get(supplierId).displayName }}</h4>
              <img :src="suppliers.get(supplierId).logoUrl" :alt="suppliers.get(supplierId).displayName" class="mt-4 hidden max-h-[70px] md:block" />
            </router-link>
            <span v-else>
              <h4 class="text-subheading-1">{{ suppliers.get(supplierId).displayName }}</h4>
            </span>
          </div>
          <div class="col-span-8">
            <div class="w-full overflow-x-scroll px-4 md:px-[50px]">
              <div role="table" class="mt-10 table w-full min-w-[540px]">
                <div role="rowgroup" class="table-header-group">
                  <div role="row" class="text-overline-1 table-row">
                    <div role="columnheader" class="table-cell border-b border-neutral-300 pb-[14px] text-center">Asset ID</div>
                    <div role="columnheader" class="table-cell border-b border-neutral-300 pb-[14px] text-center">Location</div>
                    <div role="columnheader" class="table-cell border-b border-neutral-300 pb-[14px] text-center">Coordinates</div>
                    <div role="columnheader" class="table-cell border-b border-neutral-300 pb-[14px] text-center">Allocated Amount</div>
                  </div>
                </div>
                <div role="rowgroup" class="table-row-group">
                  <div v-for="asset in assetList" :key="asset.id" role="row" class="table-row">
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">{{ asset.id }}</div>
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">{{ asset.location }}</div>
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">
                      {{ asset.coordinates.latitude }}°, {{ asset.coordinates.longitude }}°
                    </div>
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">
                      {{ getFormattedEacQuantity(asset.allocation?.eacsAllocated || 0, measurementParameter) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-10">
        <div class="flex flex-col items-center justify-center gap-8 bg-neutral-200 p-8">
          <p class="text-body-2 text-center">Assets in this portfolio have not been registered yet.</p>
        </div>
      </div>
    </section>
  </AppPage>
</template>

<script setup lang="ts">
// Libraries
import { computed, ref, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { format, parseISO } from "date-fns"
import posthog from "posthog-js"
import UtilityHelper from "@common/services/utility.helper"

// Components
import { AppPage, AppPageHeader } from "@/components/layout"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import ShareableEAC from "@/modules/accounts/components/ShareableEAC.vue"
import AccountsOrdersTable from "./AccountsOrdersTable.vue"

// Models
import { EacMeasurementParameter, getFormattedEacQuantity, formatAssetKind } from "@common/models/order"

// Services
import { useOrdersService } from "@/services/service-container"

const props = defineProps<{
  portfolioId: number
}>()

const route = useRoute()
const router = useRouter()
const ordersService = useOrdersService()

// PostHog Events
const captureViewInMarketplaceEvent = () => posthog.capture("Clicked on the 'View in Marketplace' button - Accounts Portfolio View")
const captureReviewEacsEvent = () => posthog.capture("Clicked on the 'Review & Retire EACs' button - Accounts Portfolio View")

const orderDetail = await ordersService.getOrderDetail(props.portfolioId)

const marketplacePortfolioPath = computed(() => {
  const portfolioId = orderDetail.orderSummary.portfolio.id
  return portfolioId ? { name: "wc-portfolio", params: { id: portfolioId } } : { name: "wc-openeac-exchange" }
})

const suppliers = computed(() => {
  const suppliers = new Map()
  orderDetail.devices.forEach((asset) => {
    const supplierId = asset.supplier.accountId
    suppliers.set(supplierId, asset.supplier)
  })
  return suppliers
})

const assetsBySupplierId = computed(() => {
  const assetsBySupplierId = new Map()
  orderDetail.devices.forEach((asset) => {
    const supplierId = asset.supplier.accountId
    const assetList = assetsBySupplierId.get(supplierId)
    if (assetList) {
      assetList.push(asset)
    } else {
      assetsBySupplierId.set(supplierId, [asset])
    }
  })
  return assetsBySupplierId
})

const unit = computed(() => {
  return UtilityHelper.getShorthandUnit(orderDetail.orderSummary.portfolio.units)
})

const measurementParameter = computed(() => {
  return orderDetail.orderSummary.portfolio.units === "mwh_electricity" ? EacMeasurementParameter.Electricity : EacMeasurementParameter.GhgEmissions
})

const totalAllocatedShorthand = computed(() => {
  const eacsAllocated = orderDetail?.orderSummary.eacsAllocated
  return getFormattedEacQuantity(eacsAllocated || 0, measurementParameter.value)
})

const totalAllocated = computed(() => {
  return orderDetail?.orderSummary.eacsAllocated
})

const handleClickReviewEacs = () => {
  captureReviewEacsEvent()
  router.push({ name: "wc-accounts-retire-eac" })
}

const shareableEAC = ref(null)
const isShareableEACVisible = ref(false)
onMounted(() => {
  if (route.query.showCertificate) {
    isShareableEACVisible.value = true
  }
})

const allAssetStates = computed(() => {
  const assetStates = new Set(orderDetail.devices.map((a) => a.state))
  return Array.from(assetStates).join(", ")
})

const overallAssetKind = computed(() => {
  const assetKinds = new Set(orderDetail.devices.map((d) => formatAssetKind(d.kind)))
  return Array.from(assetKinds).join(", ")
})
</script>
