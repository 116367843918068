<template>
  <div class="w-full">
    <h3 class="text-overline-2 mb-4 inline-block">Current Balance</h3>
    <div class="text-featured-number mt-0.5 flex gap-2" data-cy="cash-balance">
      {{ formatCurrency(cashBalance) }}
      <WcCTA aria-label="Withdraw" icon="wc-carbon:download" size="large" @click="openWithdrawForm" />
    </div>
    <ContactUsForm
      ref="withdrawForm"
      client:load
      :form-context="`Withdraw funds`"
      title="Withdraw funds from your account"
      success-message="We'll contact you shortly to arrange your withdrawal."
      endpoint="/transactionsv2/balance/withdrawal-request" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useTransactionService } from "@/services/service-container"
import formatCurrencyInPenniesFactory from "@common/utils/formatCurrencyInPenniesFactory"
import ContactUsForm from "@/components/ContactUsForm.vue"
import { WcCTA } from "@/components/button"

const formatCurrency = formatCurrencyInPenniesFactory()

const transactionService = useTransactionService()
const cashBalance = await transactionService.getCashBalance()

const withdrawForm = ref()
const openWithdrawForm = () => {
  withdrawForm.value.openContactForm()
}
</script>
