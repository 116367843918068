import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import EnterpriseAssets from "@/modules/asset/EnterpriseAssets.vue"
import EacInventory from "@/modules/eacs/EacInventory.vue"
import EnterpriseDashboard from "@/modules/enterprise/EnterpriseDashboard.vue"
import PortfolioLayout from "@/modules/portfolio/PortfolioLayout.vue"
import EacActivityLog from "@/modules/eacs/EacActivityLog.vue"

export const enterpriseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "wc-enterprise-dashboard",
    component: EnterpriseDashboard,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Enterprise },
    redirect: { name: "wc-enterprise-eac-inventory" },
    beforeEnter: authorizedRoute,
    children: [
      { name: "wc-enterprise-eac-inventory", path: "eac-inventory", component: EacInventory },
      { name: "wc-enterprise-assets", path: "assets", component: EnterpriseAssets },
      { name: "wc-enterprise-eac-activity-log", path: "eac-activity-log", component: EacActivityLog },
    ],
  },
]
