import { Coordinates } from "./models"
import { ASSET_KIND, AssetKind } from "./asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "./order"

export enum StoryStatusType {
  draft = "draft",
  published = "published",
  archived = "archived",
}

export class Story {
  id: number
  name: string
  subtitle: string | null
  shortDescription: string | null
  longDescription: string | null
  bodyContent: string | null
  locationDescription: string | null
  dateRangeDescription: string | null
  priceDescription: string | null
  imageUrl: string | null
  coordinates?: Coordinates[]
  impacts: string[] | null
  impactQuote: string | null
  impactQuoteAttribution: string | null
  impactStatements: string[] | null
  availabilityDescription: string | null
  deviceKindDescription: string | null
  methodologyDescription: string | null
  volumeDescription: string | null
}

export class AdminStory extends Story {
  status: StoryStatusType
  createdTime: string
  updatedTime: string
}

type PriceRange = {
  lower: number
  upper: number | null
}

export class MarketplaceStory extends Story {
  listingId: number
  totalEacs: number
  totalGco2: number
  totalDevices: number
  states: string[]
  deviceKinds: AssetKind[]
  devicePriceRangePerUnitInPenniesUsdPerMwh: PriceRange | null
  devicePriceRangePerUnitInPenniesUsdPerTco2: PriceRange | null
  deviceMethodologyUrls: string[]
}

// TODO we are casting things to `MarketplaceStory` in some places, so I'm not sure which
// of these properties are _actually_ required.  For now, i'm checking for the minimal amount
// needed to make the tests pass.  We can probably just fix the tests to use objects that have
// the expected keys on them instead of casting
function isMarketplaceStory(story: Story | MarketplaceStory): story is MarketplaceStory {
  if (!story) return false
  if (
    "totalEacs" in story ||
    "totalGco2" in story ||
    "deviceKinds" in story ||
    "devicePriceRangePerUnitInPenniesUsdPerMwh" in story ||
    "devicePriceRangePerUnitInPenniesUsdPerTco2" in story ||
    "deviceMethodologyUrls" in story
  ) {
    return true
  }
  return false
}

export const marketplaceStoryVolume = (story: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story)) {
    return ""
  }
  if (!story.totalEacs && !story.totalGco2) {
    return ""
  }
  const totalEacs = story?.totalEacs || 0
  const totalGco2 = story?.totalGco2 || 0

  const formattedEacs = getFormattedEacQuantity(totalEacs, EacMeasurementParameter.Electricity, 1).toString()
  const formattedCarbon = getFormattedEacQuantity(totalGco2, EacMeasurementParameter.CarbonDioxide, 1).toString()

  if (totalEacs && totalGco2) {
    return `${formattedEacs} (${formattedCarbon})`
  } else if (totalEacs) {
    return formattedEacs
  } else {
    return formattedCarbon
  }
}

export const marketplaceStoryKinds = (story: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story) || !story.deviceKinds) {
    return ""
  }
  return story.deviceKinds.map((kind) => ASSET_KIND[kind]).join(", ")
}

const priceOrRange = (prices: PriceRange | null): string => {
  if (prices == null) {
    return ""
  }

  if (prices.upper === null) {
    return `${prices.lower / 100}`
  }

  return `${prices.lower / 100}-${prices.upper / 100}`
}

export const marketplaceStoryPrice = (story?: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story)) {
    return story?.priceDescription ?? ""
  }

  if (story.priceDescription) {
    return story.priceDescription
  }

  if (!story.devicePriceRangePerUnitInPenniesUsdPerMwh && !story.devicePriceRangePerUnitInPenniesUsdPerTco2) {
    return ""
  }

  const priceRangeMwh = priceOrRange(story?.devicePriceRangePerUnitInPenniesUsdPerMwh)
  const priceRangeTco2 = priceOrRange(story?.devicePriceRangePerUnitInPenniesUsdPerTco2)

  if (priceRangeMwh.length > 0 && priceRangeTco2.length > 0) {
    return `$${priceRangeMwh}/MWh ($${priceRangeTco2}/tCO2)`
  } else if (priceRangeMwh.length > 0) {
    return `$${priceRangeMwh}/MWh`
  } else if (priceRangeTco2.length > 0) {
    return `$${priceRangeTco2}/tCO2`
  } else {
    return ""
  }
}

export const METHODOLOGY_URL_TO_DISPLAY_NAME_MAPPING: Record<string, string> = {
  "https://github.com/wattcarbon/WEATS/blob/main/demand-response-fiveten.md": "Demand Response EACs using a 5 in 10 baseline",
  "https://github.com/wattcarbon/WEATS/blob/main/Electrification-modeled.md": "Electrification EACs using calibrated load shape models",
  "https://github.com/wattcarbon/WEATS/pull/3": "Energy efficiency EACs using pre and post meter data",
  "https://github.com/wattcarbon/WEATS/blob/main/solar-and-battery-EACs.md": "Solar and Battery EACs using inverter data",
}

export const marketplaceStoryMethodologies = (story: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story)) {
    return []
  }
  if (!story.deviceMethodologyUrls) {
    return []
  }

  return story.deviceMethodologyUrls.map((url) => ({
    displayName: METHODOLOGY_URL_TO_DISPLAY_NAME_MAPPING[url] ?? url,
    url,
  }))
}
